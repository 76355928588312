import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import Contentbox from "../../components/contentbox"
import { calculatorDataFormatted } from "../../components/data/calculator"
import Input from "../../components/input"
import Select from "../../components/select"
import Button from "../../components/button"

const StyledCalculatorIndex = styled.div`
  p {
    margin: 0;
  }
  h3 {
    margin: 0;
    margin-bottom: 0.5rem;
  }
  .gymnast-row.tumbling {
    display: grid;
    /* grid-template-columns: 3rem 8rem 2fr 2fr 2fr 2fr 2fr 5rem; */
    grid-template-columns: 3rem 2fr 2fr 2fr 2fr 2fr 5rem;
    align-items: center;
    margin: 0.5rem 0;
    button {
      margin: 0;
      align-self: center;
      justify-self: center;
    }
    input {
      margin-right: 1rem;
    }
    p {
      padding: 0.5rem;
    }
    .single-excercise-tumbling {
      display: flex;
    }
    .gymnast-total-tumbling {
      white-space: nowrap;
      margin-bottom: 0.5rem;
    }
  }
  .round-total {
    position: relative;
    height: 1.6rem;
    h3 {
      position: absolute;
      right: 0.5rem;
    }
  }

  .trampett-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    .gymnast-row.trampett {
      display: grid;
      /* grid-template-columns: 3rem 7rem 1fr; */
      grid-template-columns: 3rem 1fr;
      align-items: center;
      margin: 0.5rem 0;
      button {
        margin: 0;
        align-self: center;
        justify-self: center;
      }
      input {
        margin-right: 1rem;
      }
      p {
        padding: 0.5rem;
      }
      .single-excercise-trampett {
        display: grid;
        grid-template-columns: auto 2rem;
      }
    }
  }
  .apparatus-total-score {
    width: calc(30% - 1rem);
    .e-score-container {
      display: flex;
      align-items: center;
      h2 {
        margin-right: 1rem;
      }
    }
  }
  @media (max-width: 768px) {
    .gymnast-row.tumbling {
      display: block;
      position: relative;
      margin-bottom: 2rem;
      .copy-button {
        position: absolute;
        bottom: 1rem;
        left: -2rem;
      }
    }
    .trampett-container {
      display: inline;
      .gymnast-row.trampett {
        display: block;
        position: relative;
        margin-bottom: 2rem;
        .copy-button {
          position: absolute;
          bottom: -1rem;
          left: -2rem;
        }
      }
    }
    .apparatus-total-score {
      width: calc(100% - 3rem);
    }
  }
`

export default class CalculatorIndex extends Component {
  state = {
    calculator: {
      trampett: {
        grandTotal: 8.0,
        eScore: 8.0,
        total: 0,
        rounds: [
          {
            total: 0,
            gymnasts: [
              {
                name: "Gymnast 1",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 2",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 3",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 4",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 5",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 6",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
            ],
          },
          {
            total: 0,
            gymnasts: [
              {
                name: "Gymnast 1",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 2",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 3",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 4",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 5",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 6",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
            ],
          },
          {
            total: 0,
            gymnasts: [
              {
                name: "Gymnast 1",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 2",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 3",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 4",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 5",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
              {
                name: "Gymnast 6",
                excercise: {
                  name: "",
                  value: 0,
                },
              },
            ],
          },
        ],
      },
      tumbling: {
        grandTotal: 8.0,
        eScore: 8.0,
        total: 0,
        rounds: [
          {
            total: 0,
            gymnasts: [
              {
                total: 0,
                name: "Gymnast 1",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 2",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 3",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 4",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 5",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 6",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
            ],
          },
          {
            total: 0,
            gymnasts: [
              {
                total: 0,
                name: "Gymnast 1",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 2",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 3",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 4",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 5",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 6",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
            ],
          },
          {
            total: 0,
            gymnasts: [
              {
                total: 0,
                name: "Gymnast 1",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 2",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 3",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 4",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 5",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
              {
                total: 0,
                name: "Gymnast 6",
                serie: [
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                  { name: "", value: 0 },
                ],
              },
            ],
          },
        ],
      },
    },
    data: {},
    loading: true,
  }

  componentDidMount() {
    const data = calculatorDataFormatted()
    this.setState({
      data: data,
      loading: false,
    })
  }

  changeName = (e, context, roundIndex, gymnastIndex) => {
    let tempCalculator = { ...this.state.calculator }
    let apparatus
    if (context === "tumbling") apparatus = tempCalculator.tumbling
    else if (context === "trampett") apparatus = tempCalculator.trampett
    apparatus.rounds[roundIndex].gymnasts[gymnastIndex].name = e.target.value
    this.setState({
      calculator: tempCalculator,
    })
  }

  changeExcerciseTumbling = (e, roundIndex, gymnastIndex, excerciseIndex) => {
    let tempCalculator = { ...this.state.calculator }
    let apparatus = tempCalculator.tumbling
    apparatus.rounds[roundIndex].gymnasts[gymnastIndex].serie[
      excerciseIndex
    ].name = e.target.value
    const matchingExcercise = this.state.data.tumbling.filter(item => {
      return item.name === e.target.value
    })
    apparatus.rounds[roundIndex].gymnasts[gymnastIndex].serie[
      excerciseIndex
    ].value = matchingExcercise[0].value
    const serieCopy = [
      ...apparatus.rounds[roundIndex].gymnasts[gymnastIndex].serie,
    ]
    const sortedByValue = serieCopy.sort((a, b) => {
      return b.value - a.value
    })
    const tempSerieTotal = sortedByValue[0].value + sortedByValue[1].value
    apparatus.rounds[roundIndex].gymnasts[gymnastIndex].total = tempSerieTotal
    let tempRoundTotal = 0
    apparatus.rounds[roundIndex].gymnasts.forEach(gymnast => {
      tempRoundTotal += gymnast.total
    })
    apparatus.rounds[roundIndex].total = tempRoundTotal
    let tempTotal = 0
    apparatus.rounds.forEach(round => {
      tempTotal += round.total
    })
    tempTotal = tempTotal / 3
    apparatus.total = tempTotal
    apparatus.grandTotal = apparatus.total + apparatus.eScore * 10
    this.setState({
      calculator: tempCalculator,
    })
  }

  changeExcerciseTrampett = (e, roundIndex, gymnastIndex) => {
    let tempCalculator = { ...this.state.calculator }
    let apparatus = tempCalculator.trampett
    apparatus.rounds[roundIndex].gymnasts[gymnastIndex].excercise.name =
      e.target.value
    const matchingExcercise = this.state.data.trampett.filter(item => {
      return item.name === e.target.value
    })
    apparatus.rounds[roundIndex].gymnasts[gymnastIndex].excercise.value =
      matchingExcercise[0].value
    let tempRoundTotal = 0
    apparatus.rounds[roundIndex].gymnasts.forEach(gymnast => {
      tempRoundTotal += gymnast.excercise.value
    })
    apparatus.rounds[roundIndex].total = tempRoundTotal
    let tempTotal = 0
    apparatus.rounds.forEach(round => {
      tempTotal += round.total
    })
    tempTotal = tempTotal / 3

    apparatus.total = tempTotal
    apparatus.grandTotal = apparatus.total + apparatus.eScore * 10
    this.setState({
      calculator: tempCalculator,
    })
  }

  setEScore = e => {
    let tempCalculator = { ...this.state.calculator }
    let apparatus
    if (e.target.id === "eScoreTumbling") apparatus = tempCalculator.tumbling
    else if (e.target.id === "eScoreTrampett")
      apparatus = tempCalculator.trampett
    console.log(e.target.value)
    apparatus.eScore = e.target.value
    apparatus.grandTotal = apparatus.total + apparatus.eScore * 10
    this.setState({
      calculator: tempCalculator,
    })
  }

  copySerieTumbling = (e, roundIndex, gymnastIndex) => {
    let tempCalculator = { ...this.state.calculator }
    let apparatus = tempCalculator.tumbling

    const matchingExcercise1 = this.state.data.tumbling.filter(item => {
      return (
        item.name ===
        apparatus.rounds[roundIndex].gymnasts[gymnastIndex].serie[0].name
      )
    })
    const matchingExcercise2 = this.state.data.tumbling.filter(item => {
      return (
        item.name ===
        apparatus.rounds[roundIndex].gymnasts[gymnastIndex].serie[1].name
      )
    })
    const matchingExcercise3 = this.state.data.tumbling.filter(item => {
      return (
        item.name ===
        apparatus.rounds[roundIndex].gymnasts[gymnastIndex].serie[2].name
      )
    })
    const matchingExcercise4 = this.state.data.tumbling.filter(item => {
      return (
        item.name ===
        apparatus.rounds[roundIndex].gymnasts[gymnastIndex].serie[3].name
      )
    })
    const matchingExcercise5 = this.state.data.tumbling.filter(item => {
      return (
        item.name ===
        apparatus.rounds[roundIndex].gymnasts[gymnastIndex].serie[4].name
      )
    })
    apparatus.rounds[roundIndex].gymnasts[gymnastIndex + 1].serie = [
      {
        name: matchingExcercise1[0] ? matchingExcercise1[0].name : "",
        value: matchingExcercise1[0] ? matchingExcercise1[0].value : 0,
      },
      {
        name: matchingExcercise2[0] ? matchingExcercise2[0].name : "",
        value: matchingExcercise2[0] ? matchingExcercise2[0].value : 0,
      },
      {
        name: matchingExcercise3[0] ? matchingExcercise3[0].name : "",
        value: matchingExcercise3[0] ? matchingExcercise3[0].value : 0,
      },
      {
        name: matchingExcercise4[0] ? matchingExcercise4[0].name : "",
        value: matchingExcercise4[0] ? matchingExcercise4[0].value : 0,
      },
      {
        name: matchingExcercise5[0] ? matchingExcercise5[0].name : "",
        value: matchingExcercise5[0] ? matchingExcercise5[0].value : 0,
      },
    ]
    this.setState({
      calculator: tempCalculator,
    })
    this.calculateTotalsTumbling(roundIndex, gymnastIndex)
  }

  copySerieTrampett = (e, roundIndex, gymnastIndex) => {
    let tempCalculator = { ...this.state.calculator }
    let apparatus = tempCalculator.trampett

    const matchingExcercise = this.state.data.trampett.filter(item => {
      return (
        item.name ===
        apparatus.rounds[roundIndex].gymnasts[gymnastIndex].excercise.name
      )
    })

    apparatus.rounds[roundIndex].gymnasts[
      gymnastIndex + 1
    ].excercise = matchingExcercise[0] || { name: "", value: 0 }
    this.setState({
      calculator: tempCalculator,
    })
    this.calculateTotalsTrampett(roundIndex)
  }

  calculateTotalsTumbling = (roundIndex, gymnastIndex) => {
    let tempCalculator = { ...this.state.calculator }
    let apparatus = tempCalculator.tumbling
    const serieCopy = [
      ...apparatus.rounds[roundIndex].gymnasts[gymnastIndex + 1].serie,
    ]
    const sortedByValue = serieCopy.sort((a, b) => {
      return b.value - a.value
    })
    const tempSerieTotal = sortedByValue[0].value + sortedByValue[1].value
    apparatus.rounds[roundIndex].gymnasts[
      gymnastIndex + 1
    ].total = tempSerieTotal
    let tempRoundTotal = 0
    apparatus.rounds[roundIndex].gymnasts.forEach(gymnast => {
      tempRoundTotal += gymnast.total
    })
    apparatus.rounds[roundIndex].total = tempRoundTotal
    let tempTotal = 0
    apparatus.rounds.forEach(round => {
      tempTotal += round.total
    })
    tempTotal = tempTotal / 3

    apparatus.total = tempTotal
    apparatus.grandTotal = apparatus.total + apparatus.eScore * 10
    this.setState({
      calculator: tempCalculator,
    })
  }

  calculateTotalsTrampett = roundIndex => {
    let tempCalculator = { ...this.state.calculator }
    let apparatus = tempCalculator.trampett
    let tempRoundTotal = 0
    apparatus.rounds[roundIndex].gymnasts.forEach(gymnast => {
      tempRoundTotal += gymnast.excercise.value
    })
    apparatus.rounds[roundIndex].total = tempRoundTotal
    let tempTotal = 0
    apparatus.rounds.forEach(round => {
      tempTotal += round.total
    })
    tempTotal = tempTotal / 3

    apparatus.total = tempTotal
    apparatus.grandTotal = apparatus.total + apparatus.eScore * 10
    this.setState({
      calculator: tempCalculator,
    })
  }

  render() {
    if (!this.state.loading) {
      return (
        <Layout>
          <StyledCalculatorIndex>
            <h1>Tumbling</h1>
            {this.state.calculator.tumbling.rounds.map((round, roundIndex) => (
              <Contentbox key={roundIndex}>
                <h3>Round {roundIndex + 1}</h3>
                {round.gymnasts.map((gymnast, gymnastIndex) => (
                  <div className="gymnast-row tumbling" key={gymnastIndex}>
                    {gymnastIndex !== 5 ? (
                      <Button
                        circle
                        invert
                        className="copy-button"
                        onClick={e => {
                          this.copySerieTumbling(e, roundIndex, gymnastIndex)
                        }}
                      >
                        <img
                          src="/images/arrow-down.png"
                          alt="copy"
                          width="10px"
                        />
                      </Button>
                    ) : (
                      <div></div>
                    )}
                    {/* <Input
                      thin
                      name="Gymnast name"
                      label="Name the gymnast"
                      type="text"
                      value={gymnast.name}
                      onChange={e => {
                        this.changeName(e, "tumbling", roundIndex, gymnastIndex)
                      }}
                    /> */}
                    {/* <div></div> */}
                    {gymnast.serie.map((excercise, excerciseIndex) => (
                      <div
                        className="single-excercise-tumbling"
                        key={excerciseIndex}
                      >
                        <Select
                          thin
                          name="Select excercise"
                          label="Select excercise"
                          value={excercise.name}
                          onChange={e => {
                            this.changeExcerciseTumbling(
                              e,
                              roundIndex,
                              gymnastIndex,
                              excerciseIndex
                            )
                          }}
                        >
                          {this.state.data.tumbling.map(excerciseData => (
                            <option
                              value={excerciseData.name}
                              key={
                                "tumbling" +
                                excerciseData.name +
                                "excIndex" +
                                excerciseIndex +
                                "gymnastIndex" +
                                gymnastIndex +
                                "roundIndex" +
                                roundIndex +
                                excerciseData.value
                              }
                            >
                              {excerciseData.name}
                            </option>
                          ))}
                        </Select>
                        <p>{excercise.value / 10}</p>
                      </div>
                    ))}
                    <p className="gymnast-total-tumbling">
                      <strong>Total: {gymnast.total / 10}</strong>
                    </p>
                  </div>
                ))}
                <div className="round-total">
                  <h3>Total: {round.total / 10}</h3>
                </div>
              </Contentbox>
            ))}
            <Contentbox className="apparatus-total-score">
              <h2>
                Total D score:{" "}
                {(this.state.calculator.tumbling.total / 10).toFixed(2)}
              </h2>
              <div className="e-score-container">
                <h2>E score: </h2>
                <Input
                  thin
                  type="number"
                  value={this.state.calculator.tumbling.eScore}
                  name="E-score"
                  step="0.1"
                  min="0"
                  max="10"
                  label="Set an E score"
                  id="eScoreTumbling"
                  onChange={this.setEScore}
                />
              </div>
              <h1>
                Total score:{" "}
                {(this.state.calculator.tumbling.grandTotal / 10).toFixed(2)}
              </h1>
            </Contentbox>
            <h1>Trampett</h1>
            <div className="trampett-container">
              {this.state.calculator.trampett.rounds.map(
                (round, roundIndex) => (
                  <Contentbox key={roundIndex}>
                    <h3>Round {roundIndex + 1}</h3>
                    {round.gymnasts.map((gymnast, gymnastIndex) => (
                      <div className="gymnast-row trampett" key={gymnastIndex}>
                        {gymnastIndex !== 5 ? (
                          <Button
                            invert
                            circle
                            className="copy-button"
                            onClick={e => {
                              this.copySerieTrampett(
                                e,
                                roundIndex,
                                gymnastIndex
                              )
                            }}
                          >
                            <img
                              src="/images/arrow-down.png"
                              alt="copy"
                              width="10px"
                            />
                          </Button>
                        ) : (
                          <div></div>
                        )}
                        {/* <Input
                          thin
                          name="Gymnast name"
                          label="Name the gymnast"
                          type="text"
                          value={gymnast.name}
                          onChange={e => {
                            this.changeName(
                              e,
                              "trampett",
                              roundIndex,
                              gymnastIndex
                            )
                          }}
                        /> */}
                        {/* <div></div> */}
                        <div className="single-excercise-trampett">
                          <Select
                            thin
                            name="Select excercise"
                            label="Select excercise"
                            value={gymnast.excercise.name}
                            onChange={e => {
                              this.changeExcerciseTrampett(
                                e,
                                roundIndex,
                                gymnastIndex
                              )
                            }}
                          >
                            {this.state.data.trampett.map(excerciseData => (
                              <option
                                value={excerciseData.name}
                                key={
                                  "trampett" +
                                  excerciseData.name +
                                  gymnastIndex +
                                  roundIndex +
                                  excerciseData.value
                                }
                              >
                                {excerciseData.name}
                              </option>
                            ))}
                          </Select>
                          <p>{gymnast.excercise.value / 10}</p>
                        </div>
                      </div>
                    ))}
                    <div className="round-total">
                      <h3>Total: {round.total / 10}</h3>
                    </div>
                  </Contentbox>
                )
              )}
            </div>
            <Contentbox className="apparatus-total-score">
              <h2>
                Total D score:{" "}
                {(this.state.calculator.trampett.total / 10).toFixed(2)}
              </h2>
              <div className="e-score-container">
                <h2>E score: </h2>
                <Input
                  type="number"
                  value={this.state.calculator.trampett.eScore}
                  name="E-score"
                  label="Set an E score"
                  step="0.1"
                  min="0"
                  max="10"
                  id="eScoreTrampett"
                  onChange={this.setEScore}
                />
              </div>
              <h1>
                Total score:{" "}
                {(this.state.calculator.trampett.grandTotal / 10).toFixed(2)}
              </h1>
            </Contentbox>
          </StyledCalculatorIndex>
        </Layout>
      )
    } else
      return (
        <Layout>
          <StyledCalculatorIndex></StyledCalculatorIndex>
        </Layout>
      )
  }
}
