import React, { Component } from "react"
import styled from "styled-components"
import { colors } from "./siteVars"

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  ${props => (props.alignLeft ? "align-items: flex-start;" : "")}

  label {
    position: absolute;
    top: 0;
    ${props => (props.inputType === "number" ? "left: -1rem;" : "")}
    margin-left: 1rem;
    margin-bottom: 1rem;
    transition: all 200ms ease-in-out;
    background: ${colors.blue};
    color: ${colors.white};
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    border-radius: 0.5rem;
    opacity: 0;
    pointer-events: none;
    ${colors.boxShadowButtonBlue}
    white-space: ${props => (props.thin ? "nowrap" : "normal")};
    &:after {
      position: absolute;
      bottom: -0.5rem;
      left: 35%;
      background: ${colors.blue};
      content: "";
      height: 0.6rem;
      width: 1rem;
      clip-path: polygon(50% 100%, 0 0, 100% 0);
    }
  }
  input {
    transition: all 200ms ease-in-out;
    margin-bottom: ${props => (props.thin ? "0.5rem" : "1rem")};
    font-size: 1rem;
    border: 1px solid ${colors.mediumGrey};
    resize: none;
    padding: ${props => (props.thin ? "0.5rem" : "1rem")};
    border-radius: 0.5rem;
    ${colors.boxShadowInput}
    &:focus {
      outline: none;
      border-color: ${colors.blue};
      + label {
        opacity: 1;
        top: ${props => (props.thin ? "-2.5rem" : "-2.5rem")};
      }
    }
    ${props =>
      props.inputType === "number"
        ? "padding: 0.8rem 0; width:4rem; margin: 0 0.5rem 0 0 ; text-align: center;"
        : ""}
  }
`

export default class Input extends Component {
  render() {
    const { props } = this
    return (
      <StyledInputContainer
        name={props.name}
        type={props.type}
        alignLeft={props.alignLeft}
        thin={props.thin}
        inputType={props.type}
      >
        <input
          type={props.type}
          value={props.value}
          name={props.name}
          placeholder={props.placeholder}
          onChange={props.onChange}
          id={props.id}
          required={props.required}
          className={props.className}
          min={props.min}
          max={props.max}
          step={props.step}
        />
        <label htmlFor={props.name}>{props.label}</label>
      </StyledInputContainer>
    )
  }
}
