export const calculatorData = {
  tumbling: {
    names: [
      "Select excercise",
      "Round off",
      "Flick flack",
      "Tucked salto",
      "Piked salto",
      "Whipback",
      "Straight salto",
      "Tucked salto 1/2",
      "Piked salto 1/2",
      "Tucked salto 1/1",
      "Straight salto 1/2",
      "Straight salto 1/1",
      "Straight salto 1 1/2",
      "Straight salto 2/1",
      "Straight salto 2 1/2",
      "Double salto tucked",
      "Double salto piked",
      "Double salto straight",
      "Double salto tucked 1/1",
      "Double salto tucked 2/1",
      "Double salto straight 1/1",
      "Double salto straight 2/1",
      "Double salto straight 3/1",
      "Triple salto tucked",
      "Triple salto piked",
      "Triple salto tucked 1/1",
      "Cartwheel",
      "Handspring",
      "Flyspring",
      "Tucked salto (at start)",
      "Tucked salto",
      "Piked salto (at start)",
      "Piked salto",
      "Straight salto (at start)",
      "Straight salto",
      "Tucked salto 1/2",
      "Tucked salto 1/1",
      "Piked salto 1/2",
      "Straight salto 1/2",
      "Straight salto 1/1 (at start)",
      "Straight salto 1/1",
      "Straight salto 1 1/2",
      "Straight salto 2/1",
      "Straight salto 2 1/2",
      "Double salto tucked",
      "Double salto piked",
      "Double salto straight",
      "Double salto tucked 1/2",
      "Double salto tucked 1 1/2",
      "Double salto piked 1/2",
      "Double salto straight 1/2",
      "Double salto straight 1 1/2",
      "Double salto straight 2 1/2",
    ],
    values: [
      0,
      1,
      2,
      2,
      2,
      3,
      3,
      3,
      3,
      4,
      4,
      5,
      6,
      7,
      8,
      8,
      9,
      11,
      10,
      12,
      13,
      15,
      18,
      16,
      19,
      20,
      1,
      2,
      2,
      2,
      2,
      3,
      3,
      3,
      4,
      3,
      4,
      4,
      5,
      4,
      6,
      7,
      8,
      9,
      11,
      13,
      15,
      12,
      14,
      14,
      16,
      18,
      20,
    ],
  },
  trampett: {
    names: [
      "Select excercise",
      "1/4 on 1/4 off",
      "Handspring 1/2 on",
      "Handspring",
      "Handspring 1/2 on 1/2 off",
      "Handspring 1/2 off",
      "Handspring 1/2 on 1/1 off",
      "Handspring 1/1 off",
      "Handspring 1/2 on 1 1/2 off",
      "Handspring 1 1/2 off",
      "Tsukahara tucked",
      "Tsukahara piked",
      "Tsukahara straight",
      "Tsukahara straight 1/1",
      "Tsukahara straight 2/1",
      "Handspring salto tucked",
      "Handspring salto piked",
      "Handspring salto straight",
      "Handspring salto tucked 1/2",
      "Handspring salto piked 1/2",
      "Handspring salto straight 1/2",
      "Handspring salto straight 1 1/2",
      "Handspring salto straight 2 1/2",
      "Double Tsukahara tucked",
      "Double Tsukahara piked",
      "Double Tsukahara tucked 1/1",
      "Handspring double tucked 1/2",
      "Handspring double piked 1/2",
      "Handspring double tucked 1 1/2",
      "Handspring double tucked 2 1/2",
      "Tucked salto",
      "Piked salto",
      "Straight salto",
      "Tucked salto 1/2",
      "Piked salto 1/2",
      "Straight salto 1/2",
      "Tucked salto 1/1",
      "Straight salto 1/1",
      "Straight salto 1 1/2",
      "Straight salto 2/1",
      "Straight salto 2 1/2",
      "Double salto tucked",
      "Double salto piked",
      "Double salto straight",
      "Double salto tucked 1/2",
      "Double salto piked 1/2",
      "Double salto tucked 1 1/2",
      "Double salto straight 1/2",
      "Double salto straight 1 1/2",
      "Double salto straight 2 1/2",
      "Double salto straight 3 1/2",
      "Triple salto tucked 1/2",
      "Triple salto piked 1/2",
      "Triple salto straight 1/2",
      "Triple salto tucked 1 1/2",
      "Triple salto tucked 2 1/2",
      "Triple salto tucked 3 1/2",
    ],
    values: [
      0,
      3,
      4,
      4,
      5,
      5,
      6,
      6,
      7,
      7,
      8,
      9,
      10,
      12,
      14,
      8,
      9,
      10,
      9,
      10,
      11,
      13,
      15,
      16,
      18,
      20,
      17,
      19,
      21,
      25,
      1,
      1,
      2,
      2,
      2,
      3,
      3,
      4,
      5,
      6,
      7,
      6,
      7,
      8,
      7,
      8,
      9,
      9,
      11,
      13,
      16,
      15,
      17,
      21,
      19,
      23,
      28,
    ],
  },
}

export const calculatorDataFormatted = () => {
  const { trampett, tumbling } = calculatorData
  const trampettFormatted = trampett.names.map((a, i) => {
    const n = {
      name: a,
      value: trampett.values[i],
    }
    return n
  })
  const tumblingFormatted = tumbling.names.map((a, i) => {
    const n = {
      name: a,
      value: tumbling.values[i],
    }
    return n
  })
  const data = {
    tumbling: tumblingFormatted,
    trampett: trampettFormatted,
  }
  return data
}

// export const finalData = () => {
//   const numberOfRounds = 3
//   const numberOfGymnasts = 6
//   const numberOfExcercises = 5

//   const tempState = {
//     tumbling: [],
//     trampett: [],
//     tumblingTotal: 0,
//     trampettTotal: 0,
//   }
//   for (let i = 0; i < numberOfRounds; i++) {
//     const gymnasts = []
//     const excercises = []
//     for (let index = 0; index < numberOfExcercises; index++) {
//       excercises.push({
//         // name: calculatorDataFormatted().tumbling[0].name,
//         // value: calculatorDataFormatted().tumbling[0].value,
//         name: "Please select",
//         value: 0,
//       })
//     }
//     for (let ind = 0; ind < numberOfGymnasts; ind++) {
//       gymnasts.push({
//         serie: [],
//         total: 0,
//         name: "Patrik",
//       })
//     }

//     tempState.tumbling.push({
//       gymnast: {
//         serie: excercises,
//         name: "Potät",
//       },
//     })
//     console.log(tempState.tumbling)
//   }
//   //   for (let i = 0; i < numberOfRounds; i++) {
//   //     const gymnasts = []
//   //     const excercises = []
//   //     for (let index = 0; index < 1; index++) {
//   //       excercises.push({
//   //         name: "",
//   //         value: "",
//   //       })
//   //     }
//   //     console.log(excercises)
//   //     for (let ind = 0; ind < numberOfGymnasts; ind++) {
//   //       gymnasts.push({
//   //         serie: excercises,
//   //         total: 0,
//   //         name: "",
//   //       })
//   //     }

//   //     tempState.trampett.push({
//   //       ["round" + (i + 1)]: gymnasts,
//   //       ["total" + (i + 1)]: 0,
//   //     })
//   //   }
//   return tempState
// }
